import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { AxiosError } from "axios";
import { CircleX } from "lucide-react";
import styled from "styled-components";

import Button from "../../components/button/button";
import {
  Select,
  SelectItem,
  SelectProvider,
} from "../../components/form/select";
import SubmitButton from "../../components/form/submit";
import Label from "../../components/label";
import { Loader } from "../../components/loader";
import ResetCss from "../../components/ResetCss";
import Title from "../../components/title";
import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import { Icons } from "../../constants/icons";

import {BaseCourse, Certificate, CorsoType} from "../../types";
import { BE_BASE_URL } from "../../utils/config";
import { useSelectStore } from "@ariakit/react";
import {axiosInstance} from "../../provider/authProvider";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  background-color: white;
  margin-bottom: 2rem;
`;

const InputField = styled.div`
  font-family: "Avenir Next", sans-serif;
  font-weight: bold;
  color: ${Color.BLUE};
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  & > label {
    padding-left: 1rem;
    padding-bottom: 0.2rem;
  }

  & > input {
    padding: 0.5rem 1rem;
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    font-size: 1rem;
  }

  & > textarea {
    padding: 0.5rem 1rem;
    border: 2px solid ${Color.LIGHT_BLUE};
    border-radius: 1rem;
    font-size: 1rem;
    overflow-y: auto;
  }
`;

const SubTitle = styled.h2`
  color: var(--blue300);
  font-family: Avenir Next Bold, sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding-left: 10px;
`;

const UploadBtnWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: end;
  margin: 1rem;
  gap: 1rem;
`;

const UploadedFilesWrapper = styled.div`
  margin-top: 0;
  background-color: #f8f9fa;
  padding: 1rem 2rem;
  border-radius: 16px;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  height: 50rem;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
  @media ${BreakpointsQuery.tablet} {
    width: 960px;
  }
`;

const BaseTable = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr 2.5fr 1fr;
  gap: 1rem;
  padding: 1rem 0.2rem;
  align-items: center;
`;

const TableHeader = styled(BaseTable)`
  font-size: 0.8rem;
  color: var(--blue300);
  font-family: Avenir Next, sans-serif;
  font-weight: bold;
  border-bottom: 0.2rem solid ${Color.LIGHT_BLUE};
`;

const ListWrapper = styled.div`
  overflow-x: visible;
  overflow-y: auto;
  height: 20rem;
  border-bottom: 0.2rem solid ${Color.LIGHT_BLUE};
`;

const TableRow = styled(BaseTable)<{ $state: number; $selected: boolean }>`
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding: 1rem 0.2rem;
  color: ${Color.LIGHT_BLUE};
  text-transform: uppercase;
  font-size: 1rem;

  background-color: ${({ $selected, $state }) => {
    return $state === -1
      ? "rgba(255, 100, 100, 0.7)"
      : $selected
      ? Color.LIGHT_GREY
      : "transparent";
  }};

  &:hover {
    background-color: ${({ $state }) => {
      return $state === -1 ? "rgba(255, 100, 100, 0.8)" : Color.LIGHT_GREY;
    }};
  }
`;

const SectionWrapper = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 16px;
  height: 100%;
`;
const LeftSectionWrapper = styled(SectionWrapper)`
  width: 43%;
  overflow-y: auto;
`;

const RightSectionWrapper = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  width: 55%;
  overflow: hidden;
`;

const PdfPreviewWrapper = styled.div`
  flex-grow: 1;
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const UploadedListWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const UploadedListElement = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
  border-radius: 8px;
  color: ${Color.LIGHT_BLUE};
  &:hover {
    background-color: ${Color.LIGHT_GREY};
  }
`;

const PreviousCertificatesPage: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedCertificate, setSelectedCertificate] =
    useState<Certificate | null>(null);

  const [selectedCertificateIds, setSelectedCertificateIds] = useState<
    number[]
  >([]);

  const [courses, setCourses] = useState<Map<number, CorsoType> | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<CorsoType | null>(null);
  const [loading, setLoading] = useState({
    show: false,
    message: "",
  });

  const courseStore = useSelectStore({
    value: `${selectedCourse?.id || ""}`,
    setValue: (value) => {
      setSelectedCourse(courses?.get(+value) || null);
    },
  });

  const emptyFormData = useMemo(() => ({
    nome: "",
    cognome: "",
    cf: "",
    titolo: "",
    codiceCorso: "",
    dataValidita: "",
    enteFormatore: "",
  }), []);
  const [formData, setFormData] = useState(emptyFormData);

  const fetchCertificates = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${BE_BASE_URL}/certificates/imported`);
      setCertificates(response.data);
    } catch (e) {
      const error = e as AxiosError
      // console.error("Errore durante il recupero dei documenti:", error);
      alert(
        error.message ||
          "Errore durante il recupero dei documenti."
      );
    }
  }, []);

  const fetchCourses = useCallback(async () => {
    try {
      const response = await axiosInstance.get<Omit<BaseCourse, "variationId">[]>(`${BE_BASE_URL}/courses`);
      if (response.data) {
        const courses: Map<number, CorsoType> = new Map<number, CorsoType>();
        response.data.forEach((c: Omit<BaseCourse, "variationId">) =>
          courses.set(c.id, {
            id: c.id,
            code: c.code,
            name: c.name,
          })
        );
        setCourses(courses);
      }
    } catch (e) {
      const error = e as AxiosError
      alert(
        error.message ||
          "Errore durante il recupero dei documenti."
      );
    }
  }, []);

  useEffect(() => {
    fetchCertificates();
    fetchCourses();
  }, [fetchCertificates, fetchCourses]);

  const renderCourseSelectItems = useCallback(
    (courses: Map<number, CorsoType>) => {
      return Array.from(courses?.entries() ?? []).map(([key, value]) => (
        <SelectItem key={`corso_${key}`} value={`${key}`}>
          {`[${value.code}] ${value.name}`}
        </SelectItem>
      ));
    },
    []
  );

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const uploadFiles = async () => {
    if (!uploadedFiles.length) return;
    const uploadedCertificates: Certificate[] = [];

    for (const file of uploadedFiles) {
      const formData = new FormData();
      formData.append("files", file);

      setLoading({ show: true, message: "Elaborazione in corso..." });
      try {
        const response = await axiosInstance.post(
          `${BE_BASE_URL}/certificates/imported`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status >= 400) {
          console.error("Errore nell'estrazione metadati:", response);
          alert(
            "Errore imprevisto, riprovare e se il problema persiste contattare l'assistenza."
          );
        }
        const data: Certificate[] = response.data;
        uploadedCertificates.push(...data);
      } catch (e) {
        const error = e as AxiosError
        // console.error("Errore durante l'upload:", error);
        alert(
          error.message || "An error occurred during upload."
        );
      } finally {
        setLoading({ show: false, message: "" });
      }
    }

    // Once all uploads are completed, update the certificates list
    setCertificates((prev) => [...prev, ...uploadedCertificates]); // Update the certificates with the new data
    setUploadedFiles([]); // Clear the uploaded files array
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input
    }
  };

  const updateCertificate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const idValue = formData.get("certificate-id");
    if (typeof idValue === "string") {
      const id = Number(idValue);

      const updatedData = {
        nome: formData.get("nome") as string,
        cognome: formData.get("cognome") as string,
        codiceFiscaleDiscente: formData.get("codiceFiscaleDiscente") as string,
        titoloCorso: selectedCourse?.name || "",
        codiceCorso: selectedCourse?.code || "",
        dataValidita: formData.get("dataValidita") as string,
        enteFormatore: formData.get("enteFormatore") as string,
      };

      let success = true;
      let errorMessage = "";
      try {
        // Effettua la richiesta per aggiornare il certificato
        await axiosInstance
          .put(`${BE_BASE_URL}/certificates/imported/${id}`, updatedData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .catch(function (error) {
            console.error(
              "Errore durante la validazione dell'attestato:",
              error
            );
            success = false;
            errorMessage =
              error.response.data.message ||
              "Errore imprevisto durante la validazione dell'attestato.";
          });
        // Refresh lista attestati con gli eventuali dati aggiornati
        await fetchCertificates();
        if (success) {
          // Se validazione ok reset del form
          setSelectedCertificate(null);
          setFormData(emptyFormData);
          courseStore.setValue("");
          setSelectedCourse(null);
        }
      } catch (e) {
        const error = e as AxiosError
        console.error("Errore imprevisto validazione attestato:", error);
        success = false;
        errorMessage =
          error.message ||
          "Errore imprevisto durante la validazione dell'attestato.";
      } finally {
        if (!success) {
          alert(errorMessage);
        }
      }
    } else {
      // console.error("ID dell'attestato non trovato o non è valido.");
      alert("ID dell'attestato non valido.");
    }
  };

  const handleRowSelect = (cert: Certificate) => {
    setSelectedCertificate(cert);
    setFormData({
      nome: cert.nome || "",
      cognome: cert.cognome || "",
      cf: cert.codiceFiscaleDiscente || "",
      titolo: cert.titoloCorso || "",
      codiceCorso: cert.codiceCorso || "",
      dataValidita: cert.dataValidita || "",
      enteFormatore: cert.enteFormatore || "",
    });
    // cerco fra i courses quello con il codice corso uguale a cert.codiceCorso
    const course = Array.from(courses?.values() || []).find(
      (c) => c.code.toLowerCase() === cert.codiceCorso.toLowerCase()
    );
    // Se lo trovo imposto il corso selezionato
    if (course) {
      courseStore.setValue(`${course.id}`);
      setSelectedCourse(course);
    } else {
      courseStore.setValue("");
      setSelectedCourse(null);
    }
  };

  const formWrapperRef = useRef<HTMLFormElement | null>(null); // Riferimento per il modulo di aggiornamento
  const handleClickOutside = useCallback((event: MouseEvent) => {
    // Verifica se il clic è stato effettuato all'esterno della tabella e del modulo
    if (
      !document
        .getElementById("certificates-list")
        ?.contains(event.target as Node) &&
      !formWrapperRef.current?.contains(event.target as Node)
    ) {
      // Se ho una selezione in memoria la rimuovo
      if (selectedCertificate) {
        setSelectedCertificate(null);
        setFormData(emptyFormData);
      }
    }
  }, [emptyFormData, selectedCertificate]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedCertificateIds([]); // Deseleziona tutti
    } else {
      const allIds = certificates
        .filter((cert) => cert.stato === 1)
        .map((cert) => cert.id); // Prendi tutti gli ID dai certificati validi
      setSelectedCertificateIds(allIds); // Seleziona tutti
    }
    setAllSelected(!allSelected); // Inverti lo stato di allSelected
  };

  const toggleCertificateSelection = (id: number) => {
    setSelectedCertificateIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const validateAll = async () => {
    if (
      window.confirm(
        "Si è sicuri di continuare l'operazione e se ne assume la responsabilità?"
      )
    ) {
      try {
        // Trova i dati dei certificati selezionati
        const selectedCertificatesData = certificates.filter((certificate) =>
          selectedCertificateIds.includes(certificate.id)
        );

        await axiosInstance.put(
          `${BE_BASE_URL}/certificates/imported`,
          selectedCertificatesData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        alert("Attestati aggiornati correttamente!");
        fetchCertificates(); // Reload elenco certificati
      } catch (e) {
        const error = e as AxiosError
        // console.error("Errore aggiornamento attestati:", error);
        alert(
          error.message ||
            "Errore durante l'aggiornamento degli attestati."
        );
      }
    }
  };

  const deleteSelectedCertificates = async () => {
    if (selectedCertificateIds.length === 0) {
      alert("Seleziona almeno un certificato da eliminare.");
      return;
    }

    if (
      window.confirm("Sei sicuro di voler eliminare i certificati selezionati?")
    ) {
      try {
        await axiosInstance.delete(`${BE_BASE_URL}/certificates/imported`, {
          params: { ids: selectedCertificateIds.join(",") },
        });
        alert("Certificati eliminati con successo!");
        fetchCertificates(); // Richiedi la lista aggiornata dei certificati
        setSelectedCertificateIds([]); // Pulire le selezioni
        setAllSelected(false); // Resettare lo stato di selezione globale
      } catch (e) {
        const error = e as AxiosError
        // console.error("Errore durante l'eliminazione dei certificati:", error);
        alert(
          error.message ||
            "Si è verificato un errore durante l'eliminazione."
        );
      }
    }
  };

  const selectStyle = {
    "--padBlock": "0.5rem",
    border: "2px solid var(--blue300)",
    borderRadius: "1rem",
    fontSize: "1rem",
  } as React.CSSProperties;

  return (
    <ResetCss style={{ width: "60rem", margin: "0 auto" }}>
      <Loader show={loading.show}>{loading.message}</Loader>
      <PageWrapper>
        <Title text={"Carica e gestisci attestati"} />
        <UploadBtnWrapper>
          <input
            id="file-upload"
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <Button
            label="Carica PDF"
            color={Color.GREEN}
            icon={Icons.BUTTONS.UPLOAD}
            onClick={() => fileInputRef.current?.click()}
          />
          {/*
          <Button
            label="Carica XSLX"
            color={Color.BLUE}
            icon={Icons.BUTTONS.UPLOAD}
            onClick={() => fileInputRef.current?.click()}
          />
          */}
        </UploadBtnWrapper>

        <UploadedFilesWrapper id="uploaded-files-container">
          <Label text="File Caricati:"></Label>
          <UploadedListWrapper id="uploaded-files-list">
            {uploadedFiles.map((file, index) => (
              <UploadedListElement key={`file_upload_${index}`}>
                {file.name}
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "red",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    const updatedFiles = uploadedFiles.filter(
                      (_, i) => i !== index
                    );
                    setUploadedFiles(updatedFiles);
                  }}
                >
                  <CircleX width={18} height={18} />
                </button>
              </UploadedListElement>
            ))}
          </UploadedListWrapper>
        </UploadedFilesWrapper>

        <ButtonWrapper>
          <Button
            label="Elabora PDF"
            color={Color.GREEN}
            icon={Icons.BUTTONS.EDIT}
            onClick={uploadFiles}
          />
        </ButtonWrapper>

        <TableWrapper id="certificates-list">
          <SubTitle>Attestati da validare</SubTitle>
          <TableHeader>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="checkbox"
                defaultChecked={allSelected}
                onChange={handleSelectAll}
              />
            </div>
            <p>NOME</p>
            <p>COGNOME</p>
            <p>TITOLO CORSO</p>
            <p>ENTE FORMATORE</p>
          </TableHeader>

          <ListWrapper>
            {certificates &&
              certificates.map((singleCertificate) => {
                const isSelected = selectedCertificateIds.includes(
                  singleCertificate.id
                );
                return (
                  <TableRow
                    role={'row'}
                    tabIndex={0}
                    key={`attestato_${singleCertificate.id}`}
                    onClick={() => handleRowSelect(singleCertificate)}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        handleRowSelect(singleCertificate)
                      }
                    }}
                    $selected={selectedCertificate?.id == singleCertificate.id}
                    $state={singleCertificate.stato}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() =>
                          toggleCertificateSelection(singleCertificate.id)
                        }
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                    <p>{singleCertificate.nome}</p>
                    <p>{singleCertificate.cognome}</p>
                    <p>{singleCertificate.titoloCorso}</p>
                    <p>{singleCertificate.enteFormatore}</p>
                  </TableRow>
                );
              })}
          </ListWrapper>
        </TableWrapper>

        <ButtonWrapper>
          <Button
            label="Elimina Selezionati"
            color={Color.RED}
            onClick={deleteSelectedCertificates}
          />
          <Button
            label="Valida Selezionati"
            color={Color.BLUE}
            onClick={validateAll}
          />
        </ButtonWrapper>

        <DataWrapper>
          <LeftSectionWrapper>
            <SubTitle style={{ fontSize: "20px", paddingBottom: "1rem" }}>
              Attestato
            </SubTitle>
            <FormWrapper
              id="update-certificate-form"
              ref={formWrapperRef}
              onSubmit={updateCertificate}
            >
              <input
                type="hidden"
                name="certificate-id"
                defaultValue={selectedCertificate?.id}
              />
              <InputField>
                <label htmlFor="nome">Nome:</label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  placeholder="Il nome del partecipante"
                  value={formData.nome}
                  onChange={(e) =>
                    setFormData({ ...formData, nome: e.target.value })
                  }
                  required
                />
              </InputField>
              <InputField>
                <label>Cognome:</label>
                <input
                  type="text"
                  id="cognome"
                  name="cognome"
                  placeholder="Il cognome del partecipante"
                  value={formData.cognome}
                  onChange={(e) =>
                    setFormData({ ...formData, cognome: e.target.value })
                  }
                  required
                />
              </InputField>
              <InputField>
                <label>Codice fiscale:</label>
                <input
                  type="text"
                  id="codiceFiscaleDiscente"
                  name="codiceFiscaleDiscente"
                  placeholder="Il CF del partecipante"
                  value={formData.cf}
                  onChange={(e) => {
                    const codiceFiscaleValue = e.target.value;
                    setFormData({ ...formData, cf: codiceFiscaleValue });
                  }}
                  required
                />
              </InputField>

              <InputField title={selectedCourse?.name ?? ""}>
                <label>Corso</label>
                <SelectProvider store={courseStore}>
                  <Select
                    style={selectStyle}
                    sameWidth={false}
                    renderValue={(value) => {
                      return value
                        ? courses?.get(+value)?.name
                        : "Seleziona un corso...";
                    }}
                  >
                    {courses ? renderCourseSelectItems(courses) : null}
                  </Select>
                </SelectProvider>
              </InputField>
              <InputField>
                <label>Data scadenza:</label>
                <input
                  type="date"
                  id="data_scadenza"
                  name="dataValidita"
                  placeholder="Data di Scadenza"
                  value={formData.dataValidita} // Usa value
                  onChange={(e) =>
                    setFormData({ ...formData, dataValidita: e.target.value })
                  }
                  required
                />
              </InputField>
              <InputField>
                <label>Ente formatore:</label>
                <input
                  type="text"
                  id="ente_formatore"
                  name="enteFormatore" // Assicurati che questo sia corretto
                  placeholder="L'ente che ha rilasciato l'attestato"
                  value={formData.enteFormatore} // Usa value
                  onChange={(e) =>
                    setFormData({ ...formData, enteFormatore: e.target.value })
                  }
                  required
                />
              </InputField>
              <SubmitButton
                label="Valida"
                form="update-certificate-form"
                color={Color.BLUE}
                name="SubmitButton"
              />
            </FormWrapper>
          </LeftSectionWrapper>
          <RightSectionWrapper>
            <SubTitle style={{ fontSize: "20px", paddingBottom: "1rem" }}>
              Anteprima PDF
            </SubTitle>
            <PdfPreviewWrapper id="pdf-preview">
              {selectedCertificate?.fileData && (
                <embed
                  src={`data:application/pdf;base64,${selectedCertificate.fileData}`}
                  width="100%"
                  height="100%"
                  type="application/pdf"
                />
              )}
            </PdfPreviewWrapper>
          </RightSectionWrapper>
        </DataWrapper>
      </PageWrapper>
    </ResetCss>
  );
};

export default PreviousCertificatesPage;

import { useTranslation } from "react-i18next";
import {BookModalFilters, DropdownOptions, Employee} from "../../../types";
import CustomSearch from "../../search";
import { Color } from "../../../constants/color";
import SingleParticipant from "./single-participant";
import Label from "../../label";
import Button from "../../button/button";
import { Icons } from "../../../constants/icons";
import React, {useCallback, useEffect, useState, useMemo} from "react";
import styled, {CSSProperties} from "styled-components";
import {
  getReserveCourseDateByCompanyId,
  reserveCourseDate
} from "../../../features/reservation/utils";
import {Select, SelectItem, SelectLabel, SelectProvider} from "../../form/select";
import * as Ariakit from "@ariakit/react";
import {getEmployeesFromCFList, initHeadquartersFilterEmployee, initJobDescriptionsFilter} from "../../../utils/utils";
import Feedback from "../feedback";
import {useAppSelector} from "../../../app/hooks";
import {selectEmployees} from "../../../features/employee/employeeSlice";

interface Props {
  participants: Employee[];
  show: boolean;
  setShow: () => void;
  variationId: number;
  isEditReserve: boolean;
  participantsReserved?: Set<string>;
  addParticipantsReserved?: (participants: string[]) => void;
  editParticipantList?: (newParticipants: Employee[]) => void;
  style?: CSSProperties;
}

const ParticipantsWrapper = styled.div<{
  $open: boolean;
}>`
  display: ${($open) => ($open.$open ? "flex" : "none")};
  transition: display 2s;
  flex-direction: column;
  align-items: center;
  row-gap: 0.8rem;
  height: 33.2rem;
  width: max-content;
  background-color: ${Color.CERAMIC};
  box-shadow: 0 0 32px -8px ${Color.LIGHT_BLUE};
  border-radius: 1.2rem;
  padding-bottom: 1.6rem;
  position: absolute;
  margin: auto auto 190px;
  z-index: 2;

  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
`;

const ModalHeader = styled.div`
  background-color: ${Color.LIGHT_BLUE};
  width: 100%;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const SearchContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-top: 1rem;
  svg {
      position: relative;
      top: 1px;
      right: 2px;
  }
`;

const SearchColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.4rem;
  margin-left: 2.2rem;
  margin-right: auto;
  width: 50rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  min-width: max-content;
  overflow-x: visible;
  overflow-y: auto;
  height: 22rem;
  margin-left: 2rem;
  margin-right: 2rem;
`;

const EditButtonsContainer = styled.div`
  display: flex;
  position: relative;
  left: 250px;
  justify-content: right;
  column-gap: 2rem;
`;

const BookModal = ({ show, setShow, participants, variationId, isEditReserve, editParticipantList, style, participantsReserved, addParticipantsReserved }: Props) => {
  const { t } = useTranslation();
  const selectedEmployees = useAppSelector(selectEmployees);
  const [participantList] = useState(participants);
  const [participantArray, setParticipantArray] = useState(participants);
  const [participantsSelected, setParticipantsSelected] = useState<Set<string>>(new Set())
  const [selected] = useState<Set<string>>(participantsSelected);
  const [loading, setLoading] = useState(true);
  const [headquarters, setHeadquarters] = useState<Set<DropdownOptions>>()
  const [jobDescription, setJobDescriptions] = useState<Set<DropdownOptions>>()
  const [saveError, setSaveError] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const [filters, setFilters] = React.useState<BookModalFilters>({
    lastNameAndFirstName: '',
    headquarter: t('book-course-filter-all'),
    jobDescription: t('book-course-filter-all'),
  });




  useEffect(() => {
    const reserved: Set<string> = new Set()
    getReserveCourseDateByCompanyId(variationId)!.then(response => {
      response.forEach(el => reserved.add(el))
      initHeadquartersFilterEmployee(participantList, setHeadquarters, t)
      initJobDescriptionsFilter(participantList, setJobDescriptions, t)
    }).finally(() => {
        setParticipantsSelected(reserved)
        setLoading(false)
    })
  }, [participantList, t, variationId]);



  if (participantsReserved) {
    participantsReserved.forEach(el => selected.add(el.replace(/'/g, '').trim()))
  }
  const reservedNotForThisDate = Array.from(selected).filter(el => !participantsSelected.has(el))
  reservedNotForThisDate.forEach(el => selected.delete(el))
  const reneviewCourseParticipants : Set<string> = useMemo(() => (new Set()), [])

  if (selectedEmployees) {
    for (let i = 0; i < Object.entries(selectedEmployees).length; i++) {
      reneviewCourseParticipants.add(selectedEmployees[i].toUpperCase())
    }
  }

  const handleSelected = (cf: string) => {
    if (selected.has(cf)) {
      selected.delete(cf);
      return;
    }
    selected.add(cf);
  };

  const filterParticipants = useCallback(() => {
    let filtered = reneviewCourseParticipants.size === 0 ? participantList : participantList.filter(el => Array.from(reneviewCourseParticipants).includes(el.cf.toUpperCase()));
    filtered = filtered.filter(el => participantsReserved ? !Array.from(participantsReserved).includes(el.cf.toUpperCase()) : el)


    if (filters.lastNameAndFirstName !== '') {
      filtered = filtered.filter(el =>
          (el.lastName.toLowerCase() + ' ' + el.firstName.toLowerCase()).includes(filters.lastNameAndFirstName)
      )
    }
    if (filters.jobDescription !== t('book-course-filter-all')) {
      filtered = filtered.filter(el =>
          el.jobDescription === filters.jobDescription
      )
    }
    if (filters.headquarter !== t('book-course-filter-all')) {
      filtered = filtered.filter(el =>
          el.workplace.toLowerCase().includes(filters.headquarter.toLowerCase())
      )
    }
    setParticipantArray(filtered)
  }, [filters.headquarter, filters.jobDescription, filters.lastNameAndFirstName, participantList, participantsReserved, reneviewCourseParticipants, t]);

  const selectJobDescription = Ariakit.useSelectStore({
    value: filters.jobDescription.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, jobDescription: value}))
    },
  });

  const selectHeadquarter = Ariakit.useSelectStore({
    value: filters.headquarter.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, headquarter: value}))
    },
  });

  useEffect(() => {
    filterParticipants()
  }, [filterParticipants, filters]);

  return (
    <ParticipantsWrapper $open={show} style={style}>
      <ModalHeader>
        <Label
          text={t('book-course-reserve-button')}
          fontSize={"20px"}
          fontWeight={"bolder"}
          textAlign={"center"}
          color={Color.WHITE}
        />
      </ModalHeader>
      <SearchContainer>
        <SearchColumn>
          <CustomSearch
              name={t('reserved-courses-detail-header-name')}
              label={t('reserved-courses-detail-search-placeholder')}
              labelProps={{
                color: Color.LIGHT_BLUE,
                text: t('reserved-courses-detail-header-name'),
                textAlign: 'left',
                textTransform: 'uppercase',
                fontWeight: 'bolder',
                width: '240px'
              }}
              width={'260px'}
              onSearch={(e) => {
                setFilters((prev) => ({ ...prev, lastNameAndFirstName: e }))
              }}
          />
        </SearchColumn>
        <SearchColumn>
          { headquarters && <SelectProvider store={selectHeadquarter}>
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.1rem",
                  }}
              >
                  <SelectLabel
                      style={{
                        paddingLeft: "0.5rem",
                        fontSize: "16px",
                        color: Color.LIGHT_BLUE,
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next, sans-serif",
                      }}
                  >
                    {t('book-course-search-headquarter')}
                  </SelectLabel>
                  <Select
                      style={{
                        minWidth: "200px",
                        borderStyle: "solid",
                        borderWidth: "2px",
                        borderRadius: "20px",
                        borderColor: Color.LIGHT_BLUE,
                        padding: "0",
                        paddingLeft: "14px",
                        height: "30px",
                        fontFamily: "Avenir Next Regular, sans-serif",
                      }}
                      renderValue={(value) => Array.from(headquarters).find(city => city.value === value)?.label}
                  >
                    {Object.entries(Array.from(headquarters)).map(([key, value]) => (
                        <SelectItem
                            key={`city_${key}`}
                            value={value.label}
                            style={{
                              justifyContent: "center"
                            }}
                        >
                          {value.label}
                        </SelectItem>
                    ))}
                  </Select>
              </div>
          </SelectProvider>}
        </SearchColumn>
        <SearchColumn>
          { jobDescription && <SelectProvider store={selectJobDescription}>
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.1rem",
                  }}
              >
                  <SelectLabel
                      style={{
                        paddingLeft: "0.5rem",
                        fontSize: "16px",
                        color: Color.LIGHT_BLUE,
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next, sans-serif",
                      }}
                  >
                    {t('reserved-courses-detail-header-job-description')}
                  </SelectLabel>
                  <Select
                      style={{
                        minWidth: "200px",
                        borderStyle: "solid",
                        borderWidth: "2px",
                        borderRadius: "20px",
                        borderColor: Color.LIGHT_BLUE,
                        padding: "0",
                        paddingLeft: "14px",
                        height: "30px",
                        fontFamily: "Avenir Next Regular, sans-serif",
                      }}
                      renderValue={(value) => Array.from(jobDescription).find(cat => cat.value === value)?.label}
                  >
                    {Object.entries(Array.from(jobDescription)).map(([key, value]) => (
                        <SelectItem
                            key={`jobDescription_${key}`}
                            value={value.value}
                            style={{
                              justifyContent: "center"
                            }}
                        >
                          {value.label}
                        </SelectItem>
                    ))}
                  </Select>
              </div>
          </SelectProvider>}
        </SearchColumn>
      </SearchContainer>
      <TableHeaderContainer>
        <Label
          text={t("reserved-courses-detail-header-name")}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"50%"}
        />
        <Label
          text={t("reserved-courses-detail-header-headquarter")}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"70%"}
        />
        <Label
          text={t("reserved-courses-detail-header-job-description")}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"25%"}
        />
        <Label
          text={t('book-course-reserve-button')}
          color={Color.LIGHT_BLUE}
          textAlign={"center"}
          textTransform={"uppercase"}
          fontWeight={"normal"}
          fontSize={"12px"}
          width={"28%"}
        />
      </TableHeaderContainer>
      <ListWrapper>
        {participantArray.length > 0 ? !loading && participantArray.map((singleParticipant, index) => {
            return (
                <SingleParticipant
                    participant={singleParticipant}
                    key={
                      singleParticipant.cf
                    }
                    onAdd={() =>
                        handleSelected(
                            singleParticipant.cf
                        )
                    }
                    index={index}
                    checked={selected.has(singleParticipant.cf)
                    }
                />
            );
        }) :
          <Label
            text={t('book-course-no-employee-available')}
            color={Color.LIGHT_BLUE}
            textAlign={"center"}
            fontWeight={"normal"}
            fontSize={"22px"}
          />
        }
      </ListWrapper>
      <EditButtonsContainer>
        <Button
          label={t('book-course-reserve-button')}
          color={Color.LIGHT_BLUE}
          icon={Icons.COMMONS.CALENDAR}
          onClick={ () => {
            if (!isEditReserve){
              reserveCourseDate(variationId, Array.from(selected))!.then(response => {
                if (response !== 200) {
                  setSaveError(true)
                  setShowDialog(true)
                  return
                }
              }).finally(() => {
                const newSelected = Array.from(participantsSelected)
                newSelected.push(...Array.from(selected))
                setParticipantsSelected(new Set(newSelected))
                if (addParticipantsReserved) addParticipantsReserved(newSelected)
                console.log('new p', newSelected)
                setShowDialog(true)
                setTimeout(() => {
                  setShow()
                  setShowDialog(false)
                }, 3000)
              })
            } else if (editParticipantList) {
              editParticipantList(getEmployeesFromCFList(participants, Array.from(selected)))
              setShow()
          }}}
        />
        <Button
          label={t("close")}
          color={Color.RED}
          icon={Icons.BUTTONS.CANCEL}
          onClick={setShow}
        />
      </EditButtonsContainer>
      { showDialog &&
        <Feedback saveError={saveError}/>
      }
    </ParticipantsWrapper>
  );
};

export default BookModal;

import styled from "styled-components";

import Title from "../../components/title";
import { useTranslation } from "react-i18next";
import {CourseDate, DropdownOptions, Employee, ReservedCourse, ReservedCourseFilters} from "../../types";
import { Color } from "../../constants/color";
import { BreakpointsQuery } from "../../constants/device";
import Course from "../../components/reserved-courses/course";
import React, {useCallback, useEffect, useState} from "react";
import {getAllReserveCourseDateByCompanyId} from "../../features/reservation/utils";
import {
  dateOptionsReserved,
  getLocale,
  initHeadquartersFilterReserved,
  initModesFilter, orderReservationByDate
} from "../../utils/utils";
import {getEmployeeByCompanyId} from "../../features/employee/utils";
import CustomSearch from "../../components/search";
import * as Ariakit from "@ariakit/react";
import {Select, SelectItem, SelectLabel, SelectProvider} from "../../components/form/select";
import {capitalize, initMonthsFilter} from "../../utils/utils";
import {Mode} from "../../constants/mode";

const ReservedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  align-items: center;
  justify-content: baseline;
`;

const DropdownSection = styled.div`
  display: flex;
  column-gap: 0.8rem;
  align-items: flex-end;
  svg {
      position: relative;
      top: 1px;
      right: 2px;
  }
  @media ${BreakpointsQuery.tablet} {
    justify-content: center;
    row-gap: 1rem;
  }
`;

const ReservedCourses = () => {
  const { t } = useTranslation();
  const [reservedCourses, setReservedCourses] = useState<ReservedCourse[]>()
  const [reservedCoursesArray, setReservedCoursesArray] = useState<ReservedCourse[]>(reservedCourses!)
  const [employeeList, setEmployeeList] = useState<Employee[]>()
  const [headquarters, setHeadquarters] = useState<Set<DropdownOptions>>()
  const [modes, setModes] = useState<Set<DropdownOptions>>()
  const [months, setMonths] = useState<Set<DropdownOptions>>()

  const [filters, setFilters] = React.useState<ReservedCourseFilters>({
    courseName: "",
    headquarter: t('book-course-filter-all'),
    month: 0,
    mode: 0,
  });

  const initPage = useCallback(() => {
    getEmployeeByCompanyId()!.then(response => setEmployeeList(response))
    getAllReserveCourseDateByCompanyId()!.then(response => {
      setReservedCourses(orderReservationByDate(response))
      setReservedCoursesArray(orderReservationByDate(response))
      initHeadquartersFilterReserved(response, setHeadquarters, t)
      initMonthsFilter(setMonths, t)
      initModesFilter(setModes, t)
      }
    );
  }, [t]);

  useEffect(() => {
    initPage()
  }, [initPage]);

  const filterCourse = useCallback(() => {
    let filtered = reservedCourses!;
    if (filters.courseName !== '') {
      filtered = filtered.filter(el =>
          Array.from(el.date)[0].courseName.toLowerCase().includes(filters.courseName)
      )
    }
    if (filters.headquarter !== t('book-course-filter-all')) {
      const filteredCourses : Map<ReservedCourse, Set<CourseDate>> = new Map();
      filtered.forEach(el => {
            const filteredDates: Set<CourseDate> = new Set();
            Array.from(el.date).forEach(date => {
                  if (date.city.toLowerCase().includes(filters.headquarter.toLowerCase())) {
                    filteredDates.add(date)
                  }
                }
            )
            filteredCourses.set(el, filteredDates)
          }
      )
      filteredCourses.forEach((dateSet, course) => {
        if (dateSet.size === 0) filteredCourses.delete(course)
      })
      filtered = filtered.filter(el => Array.from(filteredCourses.keys()).includes(el))
      filtered.forEach(el => el.date = filteredCourses.get(el)!)
    }
    if (filters.mode !== 0) {
      const filteredCourses : Map<ReservedCourse, Set<CourseDate>> = new Map();
      filtered.forEach(el => {
            const filteredDates: Set<CourseDate> = new Set();
            Array.from(el.date).forEach(date => {
                  if (date.mode === filters.mode) {
                    filteredDates.add(date)
                  }
                }
            )
            filteredCourses.set(el, filteredDates)
          }
      )
      filteredCourses.forEach((dateSet, course) => {
        if (dateSet.size === 0) filteredCourses.delete(course)
      })
      filtered = filtered.filter(el => Array.from(filteredCourses.keys()).includes(el))
      filtered.forEach(el => el.date = filteredCourses.get(el)!)
    }
    if (filters.month !== 0) {
      const filteredCourses : Map<ReservedCourse, Set<CourseDate>> = new Map();
      filtered.forEach(el => {
          const filteredDates: Set<CourseDate> = new Set();
          Array.from(el.date).forEach(date => {
              if (new Date(date.date).getMonth() + 1 === filters.month) {
                filteredDates.add(date)
              }
            }
          )
          filteredCourses.set(el, filteredDates)
        }
      )
      filteredCourses.forEach((dateSet, course) => {
        if (dateSet.size === 0) filteredCourses.delete(course)
      })
      filtered = filtered.filter(el => Array.from(filteredCourses.keys()).includes(el))
      filtered.forEach(el => el.date = filteredCourses.get(el)!)
    }
    setReservedCoursesArray(filtered)
  }, [reservedCourses, filters.courseName, filters.headquarter, filters.mode, filters.month, t]);

  const selectHeadquarter = Ariakit.useSelectStore({
    value: filters.headquarter.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, headquarter: value}))
    },
  });

  const selectMode = Ariakit.useSelectStore({
    value: filters.mode.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, mode: Number(value)}))
    },
  });
  const selectMonth = Ariakit.useSelectStore({
    value: filters.month.toString(),
    setValue: (value) => {
      setFilters((prev) => ({ ...prev, month: Number(value)}))
    },
  });

  useEffect(() => {
    filterCourse()
  }, [filterCourse, filters]);

  useEffect(() => {
  }, [reservedCoursesArray]);

  return (
    <ReservedWrapper>
      <Title text={t("reserved-courses-title")} />
      <DropdownSection>
        {reservedCoursesArray &&
            <CustomSearch
                name={t('book-course-search-keyword')}
                label={t('book-course-search-placeholder-keyword')}
                labelProps={{
                  color: Color.LIGHT_BLUE,
                  text: t('book-course-search-keyword'),
                  textAlign: 'left',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
                onSearch={(e) => {
                  setFilters((prev) => ({...prev, courseName: e}))
                }}
            />
        }
        { headquarters && <SelectProvider store={selectHeadquarter}>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
            >
                <SelectLabel
                    style={{
                      paddingLeft: "0.5rem",
                      fontSize: "16px",
                      color: Color.LIGHT_BLUE,
                      textTransform: "uppercase",
                      fontFamily: "Avenir Next Bold, sans-serif",
                    }}
                >
                  {t('book-course-search-headquarter')}
                </SelectLabel>
                <Select
                    style={{
                      minWidth: "200px",
                      borderStyle: "solid",
                      borderWidth: "2px",
                      borderRadius: "20px",
                      borderColor: Color.LIGHT_BLUE,
                      padding: "0",
                      paddingLeft: "14px",
                      height: "30px",
                      fontFamily: "Avenir Next Regular, sans-serif",
                    }}
                    renderValue={(value) => (Array.from(headquarters).find(city => city.value === value)?.label)}
                >
                  {Object.entries(Array.from(headquarters)).map(([key, value]) => (
                      <SelectItem
                          key={`city_${key}`}
                          value={value.label}
                          style={{
                            justifyContent: "center"
                          }}
                      >
                        {value.label}
                      </SelectItem>
                  ))}
                </Select>
            </div>
        </SelectProvider>}
        { modes && <SelectProvider store={selectMode}>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
            >
                <SelectLabel
                    style={{
                      paddingLeft: "0.5rem",
                      fontSize: "16px",
                      color: Color.LIGHT_BLUE,
                      textTransform: "uppercase",
                      fontFamily: "Avenir Next Bold, sans-serif",
                    }}
                >
                  {t('reserved-courses-search-lecture-mode')}
                </SelectLabel>
                <Select
                    style={{
                      minWidth: "200px",
                      borderStyle: "solid",
                      borderWidth: "2px",
                      borderRadius: "20px",
                      borderColor: Color.LIGHT_BLUE,
                      padding: "0",
                      paddingLeft: "14px",
                      height: "30px",
                      fontFamily: "Avenir Next Regular, sans-serif",
                    }}
                    renderValue={(value) => t(Array.from(modes).find(cat => cat.value === value)?.label ?? '')}
                >
                  {Object.entries(Array.from(modes)).map(([key, value]) => (
                      <SelectItem
                          key={`mode_${key}`}
                          value={value.value}
                          style={{
                            justifyContent: "center"
                          }}
                      >
                        {t(value.label)}
                      </SelectItem>
                  ))}
                </Select>
            </div>
        </SelectProvider>}
        { months && <SelectProvider store={selectMonth}>
            <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.1rem",
                }}
            >
                <SelectLabel
                    style={{
                      paddingLeft: "0.5rem",
                      fontSize: "16px",
                      color: Color.LIGHT_BLUE,
                      textTransform: "uppercase",
                      fontFamily: "Avenir Next Bold, sans-serif",
                    }}
                >
                  {t('book-course-search-month')}
                </SelectLabel>
                <Select
                    style={{
                      minWidth: "200px",
                      borderStyle: "solid",
                      borderWidth: "2px",
                      borderRadius: "20px",
                      borderColor: Color.LIGHT_BLUE,
                      padding: "0",
                      paddingLeft: "14px",
                      height: "30px",
                      fontFamily: "Avenir Next Regular, sans-serif",
                    }}
                    renderValue={(value) => capitalize(Array.from(months).find(m => m.value === value)?.label as string)}
                >
                  {Object.entries(Array.from(months)).map(([key, value]) => (
                      <SelectItem
                          key={`month_${key}`}
                          value={value.value}
                          style={{
                            justifyContent: "center"
                          }}
                      >
                        {capitalize(value.label)}
                      </SelectItem>
                  ))}
                </Select>
            </div>
        </SelectProvider>}
      </DropdownSection>
        {reservedCoursesArray && reservedCoursesArray.map((singleCourse, index) => {
            const arrayDate = Array.from(singleCourse.date);
            const varMap: Map<number, CourseDate[]> = new Map();
            arrayDate.forEach(el => {
              varMap.set(el.variationId, Array.of(el));
            })
            const datesSet = Array.from(Array.from(varMap.values())[0])
              return (datesSet.map((singleDate, dateIndex) => {
                const date: Date = new Date(singleDate.date.toString());
                const formattedDate = date.toLocaleDateString(getLocale(), dateOptionsReserved)
                const firstHour = arrayDate[1] ? arrayDate[1].start.toString().slice(0, 5) + ' - ' + arrayDate[1].end.toString().slice(0, 5) : ''
                const secondHour = arrayDate[0].start.toString().slice(0, 5) + ' - ' + arrayDate[0].end.toString().slice(0, 5)
                const participants: Employee[] = employeeList ? employeeList.filter(el => new Set(singleCourse.participantSet).has(el.cf)) : []
                return (
                    participants.length > 0 && <Course
                        type={singleCourse.courseType}
                        courseName={arrayDate[0].courseName}
                        lectureMode={Mode.get(singleDate.mode)?.icon ?? ''}
                        courseDate={formattedDate}
                        courseHours={firstHour + ' ' + secondHour}
                        city={arrayDate[0].city}
                        address={arrayDate[0].address}
                        participants={participants}
                        variationId={singleCourse.variationId}
                        key={index + dateIndex}
                    />
                );
              }));

        })}
    </ReservedWrapper>
  );
};

export default ReservedCourses;

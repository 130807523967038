import styled from "styled-components";
import Textbox from "../input";
import SubmitButton from "../submit";
import {AxiosError} from "axios";
import React, {FormEvent, LegacyRef, useEffect, useState} from "react";
import {BE_BASE_URL} from "../../../utils/config";
import {useTranslation} from "react-i18next";
import {Color} from "../../../constants/color";
import {axiosInstance, useAuth} from "../../../provider/authProvider";
import Label from "../../label";
import {selectUser, storeUser} from "../../../features/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {goToPage} from "../../../utils/utils";
import {revalidateToken} from "../../../features/user/utils";

const FormWrapper = styled.form<{
  $error: boolean;
}>`
  order: 2;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  min-width: 25rem;
  min-height: 22rem;
  height: ${(props) => (props.$error ? "24rem" : "unset")};
  max-height: 25rem;
  margin: 3.2rem auto auto;
  border-radius: 2rem 2rem 2rem 2rem;
  background-color: ${Color.WHITE};
  box-shadow: 0 0 8px 4px #e2e2e2;
    button {
        margin: auto;
    }
`;

const Header = styled.div`
  background-color: ${Color.LIGHT_BLUE};
  border-radius: 2rem 2rem 0 0;
  color: ${Color.WHITE};
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  font-family: "Avenir Next Bold", sans-serif;
  margin: 2px 2px 0 2px;
`;

const ErrorMessage = styled.div<{
  $error: boolean;
}>`
  display: ${(props) => (props.$error ? "unset" : "none")};
  text-align: center;
  max-width: 20rem;
  height: 2rem;
  margin-top: 0.6rem;
  margin-left: 2.5rem;
  background-color: ${Color.PINK};
  border-radius: 0.6rem;
  border-style: solid;
  border-width: 2px;
  border-color: ${Color.RED};
  div {
    margin-top: 0.5rem;
  }
`;

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const userStore = useAppSelector(selectUser);
  const formRef :  LegacyRef<HTMLFormElement> | undefined = React.createRef();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { token, setToken } = useAuth();



  useEffect(() => {
    if (token) {
      if (userStore.firstName.length === 0) {
        revalidateToken(setToken, dispatch).finally(() => {
          goToPage('dashboard')
        })
      } else {
        goToPage('dashboard');
      }
    }
  }, [dispatch, setToken, token, userStore, userStore.firstName, userStore.firstName.length]);

  const login = async (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    e.preventDefault();
    if (e.type !== 'submit' && e.type !== 'keyup' ) {
      return
    }
    try {
      await axiosInstance.post(BE_BASE_URL + "/auth/signin", {
        username: username,
        password: password,
      }).then((response) => {
        dispatch(storeUser({
          username: response.data.username,
          email: response.data.email,
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          role: response.data.role,
          phone: response.data.phone,
        }))
        setToken(response.data.token);
      })
    } catch (e: unknown) {
      const err = e as AxiosError;
      if (err.response && err.response.data && err.message) {
        console.log("error if", err);
        setError(true);
      } else {
        console.log("error else", err);
        // TODO Handle generic error
        setError(true);
      }
    }
  };

  return (
    <FormWrapper id={"login"}
                 ref={formRef}
                 onSubmit={login}
                 $error={error}
    >
      <Header>{t("login-header")}</Header>
      <ErrorMessage $error={error}>
        <Label text={t("login-error")} color={Color.RED} fontSize={"13px"} />
      </ErrorMessage>
      <Textbox
        label={t("login-username")}
        alt={"username"}
        form={"login"}
        autocomplete={"username"}
        name={"username"}
        placeholder={t("login-username-placeholder")}
        type={"text"}
        onChange={(e) => setUsername(e.target.value)}
        onKeyUp={(e) => login(e.nativeEvent)}
      />
      <Textbox
        label={t("login-password")}
        alt={"password"}
        form={"login"}
        autocomplete={"current-password"}
        name={"password"}
        placeholder={t("login-password-placeholder")}
        type={"password"}
        tooltip={true}
        tooltipText={{
          title: t("login-tooltip-title"),
          text: t("login-tooltip-text"),
        }}
        onChange={(e) => setPassword(e.target.value)}
        onKeyUp={(e) => login(e.nativeEvent)}
      />
      <SubmitButton
        label={t("login-submit")}
        form={"login"}
        name={"submit login"}
      />
    </FormWrapper>
  );
};

export default LoginForm;

import {AxiosError} from "axios";
import {BE_BASE_URL, BE_COURSES_ENDPOINT} from "../../utils/config";
import {CourseCarouselItem, CoursePage} from "../../types";
import {axiosInstance} from "../../provider/authProvider";
import {goToPage} from "../../utils/utils";

export function getCourseById(courseId: string){
  try {
    const axiosResponse = axiosInstance.get<CoursePage>(BE_BASE_URL + BE_COURSES_ENDPOINT + 'courseByUserAndId', {
      params: {
        courseCode: courseId,
      }
    })
    return axiosResponse.then(response => response.data
    ).catch(response => {
      if (response.status === 404) {
        goToPage('not-found');
        return undefined;
      }
    });
  } catch (e) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}

export function getAvailableCourses() {
  try {
    const axiosResponse = axiosInstance.get<CourseCarouselItem[]>(BE_BASE_URL + BE_COURSES_ENDPOINT + 'coursesByUser')
    return axiosResponse.then(response => response.data)
  } catch (e: unknown) {
    const err = e as AxiosError;
    console.log("generic error", err);
    goToPage('500');
  }
}
import React from "react";
import styled from "styled-components";
import {TextProps} from "../../../types";
import Tooltip from "../../tooltip";
import {Color} from "../../../constants/color";
import Label from "../../label";

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    text-align: center;
    color: ${Color.LIGHT_BLUE};
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
`;

const LabelWrapper = styled.div <{
    $textAlign?: string;
}>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.$textAlign ? 'unset' : 'center'};
`;

const Input = styled.input`
    margin: auto;
    width: 60%;
    height: 35px;
    border-radius: 18px;
    border-style: hidden;
    box-shadow: inset 0 0 4px 2px #E2E2E2;
    text-align: center;
    background-color: ${Color.CERAMIC};
`;

function Textbox ({
                    label,
                    alt,
                    autocomplete,
                    form,
                    name,
                    placeholder,
                    type,
                    tooltip,
                    tooltipText,
                    onChange,
                    required,
                    edit,
                    textAlign,
                    onKeyUp
                  } : TextProps) {
    return (
        <InputWrapper>
            <LabelWrapper $textAlign={textAlign}>
                <Label text={label}
                       textAlign={textAlign}
                       paddingLeft={textAlign === 'left' ? 20 : undefined}
                />
                {
                    tooltip && tooltipText && <Tooltip title={tooltipText.title} text={tooltipText.text}/>
                }
            </LabelWrapper>
            <Input
                alt={alt}
                form={form}
                autoComplete={autocomplete}
                name={name}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && onKeyUp) {
                    onKeyUp(e)
                  }
                }}
                required={required ?? true}
                readOnly={edit !== undefined ? !edit : false}
            >
            </Input>
        </InputWrapper>
    );
}

export default Textbox
import {useParams} from "react-router-dom";
import {CourseDate, CourseDateFilters, CoursePage, DropdownOptions, Employee} from "../../types";
import CourseDateCarousel from "../../components/course/date-carousel";
import styled from "styled-components";
import Title from "../../components/title";
import {useTranslation} from "react-i18next";
import {Color} from "../../constants/color";
import Label from "../../components/label";
import React, {useCallback, useEffect, useState} from "react";
import {getCourseById} from "../../features/course/utils";
import "headless-react-datepicker/dist/styles.css";
import * as Ariakit from "@ariakit/react";
import {Select, SelectItem, SelectLabel, SelectProvider} from "../../components/form/select";
import {BreakpointsQuery} from "../../constants/device";
import {
  initHeadquartersFilterDates,
  initLectureModes
} from "../../utils/utils";
import {Mode} from "../../constants/mode";
import Calendar from "../../components/form/calendar";
import {useAppDispatch} from "../../app/hooks";
import {storeEmployees} from "../../features/employee/employeeSlice";
import BookModal from "../../components/modal/book-course";
import {getEmployeeByCompanyId} from "../../features/employee/utils";
import {getParticipantsByCompanyAndDate} from "../../features/reservation/utils";

const PageWrapper = styled.div`
    margin-top: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
`;

const DropdownSection = styled.div`
    display: flex;
    column-gap: 0.8rem;
    background-color: ${Color.BLUE};
    padding: 1rem;
    border-radius: 1rem;
    align-items: flex-end;
    svg {
        position: relative;
        top: 1px;
        right: 2px;
    }
    @media ${BreakpointsQuery.tablet} {
        justify-content: center;
        row-gap: 1rem;
    }
`;



const Course = () => {
    const { t } = useTranslation();
    const { courseId, dateId } = useParams();
    const dispatch = useAppDispatch()
    const [employeeList, setEmployeeList] = useState<Employee[]>()
    const [participantsReserved, setParticipantsReserved] = useState<Set<string>>(new Set());
    const [course, setCourse] = useState<CoursePage>()
    const [courseDates, setCourseDates] = useState<Set<CourseDate>>(course?.availableDates as Set<CourseDate>)
    const [headquarters, setHeadquarters] = useState<Set<DropdownOptions>>()
    const [lectureModes, setLectureModes] = useState<Set<DropdownOptions>>()
    const [showModal, setShowModal] = useState(false)
    const [variationId, setVariationId] = useState<number>(-1)


  useEffect(() => {
    getEmployeeByCompanyId()!.then(response => {
      setEmployeeList(response)
    })
  }, []);



  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].toJSON()['type'] === 'reload' || (dateId === undefined || dateId !== '-1')) {
      dispatch(storeEmployees([]))
    }
  });

  function updateParticipantsReserved(dates: Set<CourseDate>) {
    const reserved: Set<string> = new Set()
    Array.from(dates).forEach(date => {
      getParticipantsByCompanyAndDate(date.variationId)!.then(response => {
        response.forEach(el => reserved.add(el))
      })
    })
    setParticipantsReserved(reserved)
  }

  const initPage = useCallback(() => {
    if (courseId !== undefined) {
      getCourseById(courseId.toUpperCase())!.then((response) => {
        if (response) {
          setCourse(response)
          setCourseDates(response.availableDates)
          initHeadquartersFilterDates(Array.from(response.availableDates), setHeadquarters, t)
          initLectureModes(Array.from(response.availableDates), setLectureModes, t)
          updateParticipantsReserved(response.availableDates)
        }
      })
    }
  }, [courseId, t]);

  useEffect(() => {
    initPage()
  }, [initPage]);

  useEffect(() => {
    if (courseDates) {
      updateParticipantsReserved(courseDates)
    }
  }, [courseDates, variationId]);


  const [filters, setFilters] = React.useState<CourseDateFilters>({
    lectureMode: t('book-course-filter-all'),
    headquarter: t('book-course-filter-all'),
    startDate: new Date(),
    endDate: undefined,
  });

  const filterDate = useCallback(() => {
    let filtered = Array.from(course?.availableDates ?? []);
    if (filters.lectureMode !== t('book-course-filter-all')) {
      filtered = filtered.filter(el =>
          t(Mode.get(el.mode)!.label) === filters.lectureMode
      )
    }
    if (filters.headquarter !== t('book-course-filter-all')) {
      filtered = filtered.filter(el =>
          el.city.toLowerCase().includes(filters.headquarter.toLowerCase())
      )
    }
    if (filters.endDate !== undefined) {
      filtered = filtered.filter(el =>
          new Date(el.date) <= filters.endDate! && new Date(el.date) >= filters.startDate
      )
    }
    setCourseDates(new Set(filtered))
  }, [course?.availableDates, filters.endDate, filters.headquarter, filters.lectureMode, filters.startDate, t]);

    const selectLectureMode = Ariakit.useSelectStore({
      value: filters.lectureMode.toString(),
      setValue: (value) => {
        setFilters((prev) => ({ ...prev, lectureMode: value}))
      },
    });

    const selectHeadquarter = Ariakit.useSelectStore({
      value: filters.headquarter.toString(),
      setValue: (value) => {
        setFilters((prev) => ({ ...prev, headquarter: value}))
      },
    });

    useEffect(() => {
      filterDate()
    }, [filterDate, filters]);

        return course && (
            <PageWrapper>
                <Title text={t('book-course-title')}/>
                <Label text={course.name}
                       fontSize={'20px'}
                       textTransform={'uppercase'}
                       color={Color.GREEN}
                       textAlign={'center'}
                       fontWeight={'bold'}
                />
                <DropdownSection>
                  { lectureModes && <SelectProvider store={selectLectureMode}>
                      <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.1rem",
                          }}
                      >
                          <SelectLabel
                              style={{
                                paddingLeft: "0.5rem",
                                fontSize: "16px",
                                color: Color.WHITE,
                                textTransform: "uppercase",
                                fontFamily: "Avenir Next Bold, sans-serif",
                              }}
                          >
                            {t('book-course-lecture-mode')}
                          </SelectLabel>
                          <Select
                              style={{
                                minWidth: "200px",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                borderRadius: "20px",
                                borderColor: Color.LIGHT_BLUE,
                                padding: "0",
                                paddingLeft: "14px",
                                height: "30px",
                                fontFamily: "Avenir Next Regular, sans-serif",
                              }}
                              renderValue={(value) => Array.from(lectureModes).find(mode => mode.value === value)?.label}
                          >
                            {Object.entries(Array.from(lectureModes)).map(([key, value]) => (
                                <SelectItem
                                    key={`mode_${key}`}
                                    value={value.label}
                                    style={{
                                      justifyContent: "center"
                                    }}
                                >
                                  {t(value.label)}
                                </SelectItem>
                            ))}
                          </Select>
                      </div>
                  </SelectProvider>}
                  { headquarters && <SelectProvider store={selectHeadquarter}>
                      <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.1rem",
                          }}
                      >
                          <SelectLabel
                              style={{
                                paddingLeft: "0.5rem",
                                fontSize: "16px",
                                color: Color.WHITE,
                                textTransform: "uppercase",
                                fontFamily: "Avenir Next Bold, sans-serif",
                              }}
                          >
                            {t('book-course-search-headquarter')}
                          </SelectLabel>
                          <Select
                              style={{
                                minWidth: "200px",
                                borderStyle: "solid",
                                borderWidth: "2px",
                                borderRadius: "20px",
                                borderColor: Color.LIGHT_BLUE,
                                padding: "0",
                                paddingLeft: "14px",
                                height: "30px",
                                fontFamily: "Avenir Next Regular, sans-serif",
                              }}
                              renderValue={(value) => Array.from(headquarters).find(city => city.value === value)?.label}
                          >
                            {Object.entries(Array.from(headquarters)).map(([key, value]) => (
                                <SelectItem
                                    key={`city_${key}`}
                                    value={value.label}
                                    style={{
                                      justifyContent: "center"
                                    }}
                                >
                                  {value.label}
                                </SelectItem>
                            ))}
                          </Select>
                      </div>
                  </SelectProvider>}
                  <Calendar
                      isRange={true}
                      setDateFilters={(rangeDate : Date[]) => {
                        setFilters((prev) => ({
                          ...prev,
                          startDate: rangeDate[0],
                          endDate: rangeDate[1],
                        }))
                      }}
                  />
                </DropdownSection>
                <CourseDateCarousel
                    dates={courseDates}
                    setVariationId={(variation: number) => setVariationId(variation)}
                    setShowModal={() => setShowModal(!showModal)}
                />
                { employeeList && variationId !== -1 &&
                    <BookModal
                        participants={employeeList}
                        show={showModal}
                        setShow={() => setShowModal(false)}
                        variationId={variationId}
                        isEditReserve={false}
                        participantsReserved={participantsReserved}
                        addParticipantsReserved={(participants: string[]) => {
                          const updatedParticipants = Array.from(participantsReserved);
                          updatedParticipants.push(...participants)
                          setParticipantsReserved(new Set(updatedParticipants));
                        }}
                        style={{top: '360px'}}
                    />
                }
            </PageWrapper>
        );
};

export default Course
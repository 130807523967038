import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {Employee, ReservedCourseItem} from "../../../../types";
import CustomSearch from "../../../search";
import { Color } from "../../../../constants/color";
import SingleParticipant from "./single-participant";
import Label from "../../../label";
import Button from "../../../button/button";
import { Icons } from "../../../../constants/icons";
import React, {useCallback, useEffect, useState} from "react";
import {BreakpointsQuery} from "../../../../constants/device";
import {reserveCourseDate} from "../../../../features/reservation/utils";
import {filterParticipantAlreadyReserved, getCFListFromEmployees} from "../../../../utils/utils";
import BookModal from "../../../modal/book-course";
import {getEmployeeByCompanyId} from "../../../../features/employee/utils";
import Feedback from "../../../modal/feedback";

interface Props {
  course: ReservedCourseItem;
  show: boolean;
}

const ParticipantsWrapper = styled.div<{
  $open: boolean;
}>`
  display: ${($open) => ($open.$open ? "flex" : "none")};
  transition: display 2s;
  flex-direction: column;
  align-items: center;
  row-gap: 0.8rem;
  max-height: 24rem;
  width: 100%;
  background-color: ${Color.CERAMIC};
  box-shadow: 0 0 8px 6px ${Color.SHADOW};
  border-bottom-left-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  ::-webkit-scrollbar {
    position: relative;
    display: flex;
    left: 4rem;
    width: 0.6rem;
    border-radius: 4rem;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4rem;
    background-color: ${Color.LIGHT_BLUE};
  }
  ::-webkit-scrollbar-track-piece {
    border-radius: 4rem;
    background-color: ${Color.GREY};
  }
  @media ${BreakpointsQuery.tablet} {
      width: 990px;
  }
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.4rem;
  margin-left: 231px;
  margin-right: auto;
  width: 50rem;
  @media ${BreakpointsQuery.tablet} {
      margin-left: 107px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  min-width: max-content;
  overflow-x: visible;
  overflow-y: auto;
  max-height: 22rem;
`;

const EditButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 4rem;
  margin-top: 0.4rem;
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    justify-items: flex-end;
    width: 1000px;
    column-gap: 40px;
`;

const Participants = ( {show, course} : Props ) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState(false);
    const [participantList, setList] = useState<Employee[]>([]);
    const [newParticipantList, setNewList] = useState<Employee[]>([]);
    const [showModal, setShowModal] = useState(false)
    const [saveError, setSaveError] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [modified, setModified] = useState<boolean>(false)
    const [employeeList, setEmployeeList] = useState<Employee[]>([])


    const initPage = useCallback(() => {
      getEmployeeByCompanyId()!.then(response => {
        setEmployeeList(response)
        setList(Object.assign([], course.participants))
        setNewList(Object.assign([], course.participants))
      })
    }, [course.participants]);

    useEffect(() => {
      initPage()
    }, [initPage]);


    function removeParticipant(participants : Employee[] , index : number) {
      setNewList(newParticipantList.filter( el => el !== participants[index]))
    }
    function searchParticipant(input : string) {
        const filteredList: Employee[] = course.participants.filter(el => (el.lastName.toLowerCase() + ' ' + el.firstName.toLowerCase()).includes(input) ? el : null)
        if (input.length !== 0) {
          setList(filteredList)
          setNewList(filteredList)
        }
        else if (input.length === 0) {
          setList(course.participants)
          setNewList(course.participants)
        }
    }
    return (
        <ParticipantsWrapper $open={show}>
            <Row>
              <CustomSearch name={t('reserved-courses-detail-header-name')}
                          labelProps={{
                            color: Color.LIGHT_BLUE,
                            text: t('reserved-courses-detail-header-name'),
                            fontWeight: 'bolder'
                          }}
                          width={'500px'}
                          onSearch={ (input) => searchParticipant(input)}
            />
            </Row>
            <TableHeaderContainer>
                <Label text={t('reserved-courses-detail-header-name')}
                       color={Color.LIGHT_BLUE}
                       textAlign={'left'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'12px'}
                       width={'250px'}
                />
                <Label text={t('reserved-courses-detail-header-headquarter')}
                       color={Color.LIGHT_BLUE}
                       textAlign={'center'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'12px'}
                       width={'200px'}
                />
                <Label text={t('reserved-courses-detail-header-job-description')}
                       color={Color.LIGHT_BLUE}
                       textAlign={'center'}
                       textTransform={'uppercase'}
                       fontWeight={'normal'}
                       fontSize={'12px'}
                       width={'200px'}
                />
            </TableHeaderContainer>
            <ListWrapper>
                {participantList.map((singleParticipant, index) => {
                    return (
                        <SingleParticipant
                            participant={singleParticipant}
                            key={index}
                            edit={edit}
                            toBeRemoved={!newParticipantList.includes(singleParticipant)}
                            onDelete={ () => {
                              removeParticipant(participantList, index)
                              setModified(true)
                            }}
                        />
                    );
                })}
            </ListWrapper>
            <EditButtonsContainer>
                <Button label={t('reserved-courses-detail-button-edit')}
                        color={Color.RED}
                        icon={Icons.BUTTONS.EDIT}
                        onClick={ () => setEdit(!edit)}
                />
                <Button label={t('reserved-courses-detail-button-add')}
                        color={Color.GREEN}
                        icon={Icons.BUTTONS.PLUS}
                        onClick={() => setShowModal(true)}
                />
                { modified &&
                  <Button label={t('reserved-courses-detail-button-rollback')}
                       color={Color.RED}
                       icon={Icons.BUTTONS.RESET}
                       onClick={() => {
                         setEdit(false)
                         setList(course.participants)
                         setNewList(participantList)
                         setModified(false)
                       }}
                  />
                }
                { modified &&
                  <Button label={t('reserved-courses-detail-button-save')}
                         color={Color.LIGHT_BLUE}
                         icon={Icons.COMMONS.CALENDAR}
                         onClick={() => {
                           reserveCourseDate(course.variationId, getCFListFromEmployees(newParticipantList))!.then(result => {
                             if (result !== 200) {
                               setSaveError(true)
                               return
                             }
                           })
                           setShowDialog(true)
                           setTimeout(() => {
                             setShowDialog(false)
                             window.location.reload()
                           }, 2000)
                         }}
                  />
                }
            </EditButtonsContainer>
          {showModal &&
              <BookModal
                  participants={filterParticipantAlreadyReserved(employeeList, newParticipantList)}
                  show={showModal}
                  setShow={() => setShowModal(false)}
                  variationId={course.variationId}
                  isEditReserve={true}
                  editParticipantList={(newPrList : Employee[]) => {
                      const newList: Employee[] = Object.assign([], newParticipantList);
                      newList.push(...newPrList)
                      setList(newList);
                      setNewList(newList);
                      setModified(true)
                  }}
                  style={{
                    position: "fixed",
                    top: '160px'
                  }}
              />
          }
          { showDialog &&
              <Feedback saveError={saveError}/>
          }
        </ParticipantsWrapper>
    );
};

export default Participants;
